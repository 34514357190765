.pricemain {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
    font-family: "Open Sans";
    width: 1170px;
    margin: 0 auto;
}
.price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
}
.price-table tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 14px;
}
.price-table tr td:first-child {
    border-left: 0 none;
}
.price-table tr td:not(:first-child) {
    text-align: center;
}
.price-table tr:nth-child(even) {
    background-color: #FFFFFF;
}
.price-table tr:hover {
    background-color: #EEEEEE;
}
.price-table .fa-check {
    color: #0a66c2;
}
.price-table .fa-times {
    color: #D8D6E3;
}

/* Highlighted column */
.price-table tr:nth-child(2n) td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.25);
}
.price-table tr td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.15);
    padding: 8px 48px;
}
/* .price-table tr td:nth-child(3) .fa-check,
.price-table tr:nth-child(2n) td:nth-child(3) .fa-check {
    /* color: #ffffff; */
/* } */


.price-table tr.price-table-head td {
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    text-transform: uppercase;
}
.price-table tr.price-table-head {
    background-color: #0a66c2;
    color: #FFFFFF;
}
.price-table td.price {
    
    padding: 16px 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat";
}
.price-table td.price a {
    background-color: #0a66c2;
    color: #FFFFFF;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat";
    text-transform: uppercase;
    display: inline-block;
    border-radius: 64px;
}
.price-table td.price-table-popular {
    font-family: "Montserrat";
    border-top: 3px solid #0a66c2;
    color: #0a66c2;
    text-transform: uppercase;
    font-size: 12px;
    padding: 12px 48px;
    font-weight: 700;
}
.price-table .price-blank {
    background-color: #fafafa;
    border: 0 none;
}

.price-table svg {
    width: 90px;
    fill: #0a66c2;
}

.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px;
    margin-left: 5px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #3c6ef9;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #3c6ef9;
  }
  
  
  input:checked + .slider:before {
    -webkit-transform: translateX(45px);
    -ms-transform: translateX(45px);
    transform: translateX(45px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .plan-pricem{
    margin: 0;
    font-family:fantasy;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    color: #383d43;
  }

  .plan-price {
    margin: 0;
    font-family:fantasy;
    font-size: 2rem;
    line-height: 1;
    font-weight: 500;
    color: #343a40;
}

.headtag{
    margin: 0;
    font-family:fantasy;
    font-size: 2rem;
    line-height: 1;
    font-weight: 500;
    
}